import {useAuth, useUser} from 'core/hooks';
import {goSurvey} from 'data/goSurvey.json';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {openLoginScreen} from 'redux/modules/auth';
import {useDispatch, useSelector} from 'react-redux';
import WelcomeGoSurvey from './components/WelcomeGoSurvey';
import {
  Box,
  Stack,
  useTheme,
  Container,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {fetchGoSurveyResults, getGoSurveyResults} from 'redux/modules/survey';
import {
  Faq,
  VideoBanner,
  BackgroundPaper,
  LayoutStaticBanner,
  RequestErrorLoader,
} from 'mui-core';
import {
  CloudIcon,
  NetBanking,
  CheckDoneIcon,
  EducationAward,
} from 'mui-core/Icons';
import {ArrowRight, goSurveyVideoBanner} from 'assets/images';

const Icons = [EducationAward, CheckDoneIcon, CloudIcon, NetBanking];
const {
  faq,
  banners,
  goSurveyBtn,
  discoverYour = {},
  getYourTopCareer = {},
  goSurveyBanner: {title = '', description = ''} = {},
  purpleBanner: {purpleBannerTitle = '', purpleBannerArray = []},
} = goSurvey;
let listenUser = false;

const updatedArray = purpleBannerArray.map((item, index) => ({
  ...item,
  SVGIcon: Icons[index],
}));

const GOSurvey = () => {
  const theme = useTheme();
  const [token] = useAuth();
  const user = useUser();
  const history = useHistory();
  const dispatch = useDispatch();
  const {request} = useSelector(getGoSurveyResults);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  const handleSubmitModal = () => {
    setShowWelcomeModal(false);
    history.push('/go-survey');
  };

  const handleSurveyNavigation = () => {
    dispatch(
      fetchGoSurveyResults(surveyResults => {
        if (surveyResults && surveyResults.length === 0) {
          setShowWelcomeModal(true);
        } else {
          history.push('/go-survey');
        }
      }),
    );
  }; // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (listenUser && user?.data?.student_uuid) {
      handleSurveyNavigation();
      listenUser = false;
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenLogin = () => {
    if (!token) {
      listenUser = true;
      dispatch(
        openLoginScreen({
          // callback: () => handleSurveyNavigation(),
        }),
      );
    } else {
      handleSurveyNavigation();
    }
  };

  return (
    <BackgroundPaper>
      <VideoBanner bannerURL={goSurveyVideoBanner}>
        <Stack gap={{xs: 2, md: 5}}>
          <Typography
            color='white'
            component='h1'
            variant='header-lg-bold'
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <Typography
            color='white'
            component='p'
            variant='text-xl-regular'
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </Stack>
      </VideoBanner>
      <Stack
        alignItems='center'
        justifyContent='center'
        mt={{xs: 2, sm: 5, md: '100px'}}>
        <Stack gap={{xs: '32px', md: '150px'}}>
          <LayoutStaticBanner
            imageFirst={true}
            button={goSurveyBtn}
            padding='0px 0px 0px 64px'
            gap={{xs: 5, md: '74px'}}
            buttonEndIcon={ArrowRight}
            buttonAction={handleOpenLogin}
            detail={{
              heading: discoverYour.title,
              imgpath: discoverYour.image,
              subHeading: discoverYour.description,
            }}
          />
          <Box
            width='99.7vw'
            position='relative'
            sx={{
              backgroundImage: `linear-gradient(270deg, #5E92F4 0%, #A290F6 76%)`,
            }}>
            <Box
              sx={{
                padding: {xs: '5%', md: '0 0 0 10%'},
              }}>
              <LayoutStaticBanner
                maxWidth='100%'
                imageFirst={false}
                button={goSurveyBtn}
                disableGutters={true}
                buttonEndIcon={ArrowRight}
                buttonAction={handleOpenLogin}
                imageWidth={getYourTopCareer.imageWidth}
                textContentWidth={getYourTopCareer.textContentWidth}
                detail={{
                  imgpath: getYourTopCareer.image,
                  heading: getYourTopCareer.title,
                  subHeading: getYourTopCareer.description,
                  headingColor: theme.palette.grey.contrastText,
                  descriptionColor: theme.palette.grey.contrastText,
                }}
              />
            </Box>
          </Box>
          {banners.map((banner, index) => (
            <LayoutStaticBanner
              key={banner.key}
              gap={{xs: 5, md: '74px'}}
              buttonEndIcon={ArrowRight}
              imageFirst={index % 2 === 0}
              buttonAction={handleOpenLogin}
              imageWidth={{xs: '100%', md: '50%'}}
              textContentWidth={{xs: '100%', md: '50%'}}
              padding={index % 2 === 0 && '0px 0px 0px 64px'}
              button={banner.button ? banner?.buttonLabel || '' : ''}
              detail={{
                heading: banner.title,
                imgpath: banner.image,
                subHeading: banner.description,
              }}
            />
          ))}
          <Container maxWidth='lg'>
            <Stack
              p={5}
              gap={5}
              borderRadius={3}
              boxShadow='0px 24px 48px -12px #1018282E'
              sx={{
                backgroundImage: `linear-gradient(270deg, #5E92F4 0%, #A290F6 76%)`,
              }}>
              <Typography
                color='white'
                component='h2'
                textAlign='center'
                variant='header-md-bold'>
                {purpleBannerTitle}
              </Typography>
              <Stack
                gap={4}
                spacing={{xs: 1, md: 0}}
                direction={{xs: 'column', md: 'row'}}>
                {updatedArray?.map(({title, desc, SVGIcon}, index) => (
                  <Stack gap={{xs: 2, md: 4}} key={index}>
                    <Stack
                      bgcolor='white'
                      borderRadius='50%'
                      alignItems='center'
                      justifyContent='center'
                      mx={{xs: 'auto', md: '60px'}}
                      width={isMobile ? '40px' : '72px'}
                      height={isMobile ? '40px' : '72px'}>
                      <SVGIcon
                        endColor='#A290F6'
                        startColor='#5E92F4'
                        fontSize={isMobile ? '24px' : '40px'}
                      />
                    </Stack>
                    <Stack
                      gap={{xs: 2, md: 3}}
                      alignItems={{xs: 'center', md: 'flex-start'}}>
                      <Typography
                        color='white'
                        component='h6'
                        variant='title-sm-bold'>
                        {title}
                      </Typography>
                      <Typography
                        color='white'
                        component='p'
                        variant='text-md-regular'>
                        {desc}
                      </Typography>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Container>
          <Faq data={faq}></Faq>
        </Stack>
      </Stack>
      <WelcomeGoSurvey
        visible={showWelcomeModal}
        onSubmit={handleSubmitModal}
        onClose={() => setShowWelcomeModal(false)}
      />
      {request && <RequestErrorLoader hideEmpty fullScreen body={{request}} />}
    </BackgroundPaper>
  );
};

export default GOSurvey;
