import {Accordion, themeColorMode} from 'mui-core';
import {Close, ExpandMore} from '@mui/icons-material';
import {apprenticeshipDetailsBanner} from 'assets/images';
import {
  Box,
  Dialog,
  styled,
  Divider,
  useTheme,
  IconButton,
  Typography,
  DialogContent,
} from '@mui/material';
import {learnMoreAbout} from 'data/opportunities.json';

const ModalBanner = styled(Box)(({theme}) => ({
  flexShrink: 0,
  width: '100%',
  height: '250px',
  overflow: 'hidden',
  position: 'relative',
  backgroundSize: 'cover',
  backgroundPosition: 'top',
  backgroundRepeat: 'no-repeat',
  backgroundBlendMode: 'overlay',
  backgroundImage: `url(${apprenticeshipDetailsBanner}),linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5))`,
}));

const CustomAccordion = styled(Accordion)(({theme}) => ({
  margin: '0px',
  width: '100%',
  padding: '0px',
  boxShadow: 'none',
  '& .MuiAccordionSummary-root': {
    paddingLeft: '0px',
  },
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& .MuiAccordionDetails-root': {
    paddingTop: '0px',
    paddingLeft: '16px',
  },
}));

const StyledCloseIcon = styled(IconButton)(({theme}) => ({
  top: '30px',
  right: '30px',
  padding: '8px',
  color: 'black',
  borderRadius: '50%',
  position: 'absolute',
  backgroundColor: 'white !important',
  '&:hover': {
    backgroundColor: 'white !important',
  },
}));

const {learnMoreModalTitle = '', learnMoreAboutQuestion = []} =
  learnMoreAbout || {};

const LearnMoreAboutModal = ({modalHandler, showModal}) => {
  const theme = useTheme();

  const handleClose = () => {
    modalHandler(false);
  };

  return (
    <Dialog
      maxWidth='xl'
      open={showModal}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <ModalBanner>
        <Typography
          variant='h1'
          textAlign='center'
          color={theme.palette.success.contrastText}
          sx={{
            top: '50%',
            left: '50%',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
          }}
          dangerouslySetInnerHTML={{__html: learnMoreModalTitle}}
        />
        <StyledCloseIcon
          size='large'
          onClick={handleClose}
          aria-label='Apprenticeship-close'>
          <Close fontSize='small' />
        </StyledCloseIcon>
      </ModalBanner>
      <DialogContent sx={{px: {sm: 1.5, md: 10}, pt: 0}}>
        {learnMoreAboutQuestion?.map((paragraph, index) => (
          <Box key={index}>
            <CustomAccordion
              defaultOpen={true}
              titleDivider={false}
              expandIcon={<ExpandMore />}
              sx={{background: 'transparent', px: 0}}
              header={
                <Typography
                  variant='subtitle1'
                  color={themeColorMode(
                    theme,
                    theme.palette.shadeyBlack.main,
                    theme.palette.midGray.main,
                  )}>
                  {paragraph.question}
                </Typography>
              }>
              <Typography
                py={1}
                variant='body1'
                color={themeColorMode(theme, theme.palette.grey.main, 'white')}>
                {paragraph.answer}
              </Typography>
            </CustomAccordion>
            <Divider />
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default LearnMoreAboutModal;
